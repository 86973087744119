export const ROOM_UUID = "/dde338d2-989f-43ae-8c07-2788f788d433";
export const ROOM_UUID_pr = "443ad998-e3f5-461d-a5ca-e547df773369";
const ROOM_STG = "840019de-152d-4557-9b51-fda5f43629fd";

// const stg = "https://stg.tdh.start-tech.ae/api";
const stg = "https://staging.tdh.start-tech.ae/api";
const appSgp = "https://dashboard-sgp.thedigitalhotelier.com/api";
const production = "https://dashboard.thedigitalhotelier.com/api";

const redirect_production = "https://dashboard.thedigitalhotelier.com";
const redirect_app_sgp = "https://dashboard-sgp.thedigitalhotelier.com";

export const API_URL = production;
export const redirect = redirect_production;

export const phoneFormat = API_URL.includes("sgp") ? "ph" : "ae";
// https://app-sgp.thedigitalhotelier.com
